



























































import {Component, Watch} from 'vue-property-decorator';
import DropDown from '@/components/DropDown.vue';
import PieChart from '@/components/PieChart.vue';
import {vxm} from '@/store';
import moment from 'moment';
import {mixins} from 'vue-class-component';
import NumbersMixin from '@/mixins/NumbersMixin';
import Paginate from 'vuejs-paginate';
import DatePickerModal from '@/components/modals/DatePickerModal.vue';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import {CashEarningInterface} from '@/types/CashEarningInterface';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

@Component({
  components: {DatePickerModal, PieChart, DropDown, Paginate, ConfirmationModal},
})
export default class FinanceStatistics extends mixins(NumbersMixin, MomentDateMixin) {
  dateFilters = {
    year: 'Last Year',
    month: 'Last Month',
    week: 'Last Week',
    allTime: 'All time',
  };
  filters = {
    date: 'allTime',
  };
  data: CashEarningInterface[] = [];
  pieData = [];
  statisticDates: {start: string; end: string} = {
    start: '',
    end: '',
  };
  showRequestSuccess = false;

  /* pagination params */
  totalPages = 1;
  params: any = {
    page: 1,
    take: 10,
  };

  get totalNet() {
    return new Intl.NumberFormat('en-EN', {style: 'currency', currency: 'USD'}).format(
      this.pieData.length
        ? parseFloat(
            this.pieData
              .map((x: {pricePay: {amount: number; type: string}[]}) =>
                x.pricePay
                  .filter((y) => y.type !== 'store')
                  .map((y) => y.amount)
                  .reduce((prev, current) => prev + current),
              )
              .reduce((prev, current) => prev + current)
              .toFixed(2),
          )
        : 0,
    );
  }

  get preparePie() {
    const data = {
      subscriptions: 0,
      tip: 0,
      saleVideo: 0,
      salePhoto: 0,
      store: 0,
    };
    this.pieData.forEach((item: any) => {
      const subscriptions = item.pricePay
        .filter(
          (x) =>
            x.type === 'monthly_subscription' || x.type === 'yearly_subscription' || x.type === 'trial_subscription',
        )
        .map((x) => x.amount);
      const tip = item.pricePay.filter((x) => x.type === 'tip').map((x) => x.amount);
      const saleVideo = item.pricePay.filter((x) => x.type === 'sale_video').map((x) => x.amount);
      const salePhoto = item.pricePay.filter((x) => x.type === 'sale_photo').map((x) => x.amount);
      data.subscriptions += subscriptions.length ? subscriptions.reduce((prev, current) => prev + current) : 0;
      data.tip += tip.length ? tip.reduce((prev, current) => prev + current) : 0;
      data.saleVideo += saleVideo.length ? saleVideo.reduce((prev, current) => prev + current) : 0;
      data.salePhoto += salePhoto.length ? salePhoto.reduce((prev, current) => prev + current) : 0;
    });
    data.subscriptions = parseFloat(data.subscriptions.toFixed(2));
    data.tip = parseFloat(data.tip.toFixed(2));
    data.saleVideo = parseFloat(data.saleVideo.toFixed(2));
    data.salePhoto = parseFloat(data.salePhoto.toFixed(2));
    /* return sub tip vid store */
    return [
      {
        label: 'Subscriptions',
        color: '#a4de02',
        value: data.subscriptions,
      },
      {
        label: 'Tips',
        color: '#1e5631',
        value: data.tip,
      },
      {
        label: 'Sale Video',
        color: '#4c9a2a',
        value: data.saleVideo,
      },
      {
        label: 'Sale Photo',
        color: '#89ff00',
        value: data.salePhoto,
      },
    ];
  }

  created() {
    this.loadPieData();
  }

  loadPieData() {
    vxm.model
      .getEarningsByPeriod(`&dateFrom=${this.statisticDates.start}&dateTo=${this.statisticDates.end}&groupBy=year`)
      .then((res) => {
        this.pieData = res.data.items;
      })
      .catch((error) => {
        return error;
      });
  }
  sendPayoutRequest() {
    vxm.model.sendPayoutRequest().then(() => {
      this.showRequestSuccess = true;
    });
  }

  changeDates(key: string) {
    this.filters.date = key;
    this.params.page = 1;
    if (key === 'allTime') {
      this.statisticDates = {end: '', start: ''};
    } else {
      this.statisticDates.end =
        moment()
          .utc(false)
          .subtract(1, key as any)
          .endOf(key as any)
          .format('YYYY-MM-DD') + 'T09:00:00.000Z';
      this.statisticDates.start =
        moment()
          .utc(false)
          .subtract(1, key as any)
          .startOf(key as any)
          .format('YYYY-MM-DD') + 'T09:00:00.000Z';
    }
    // this.loadHistory();
    this.loadPieData();
  }

  statisticDatesChanged(dates) {
    this.statisticDates = dates;
    this.statisticDates.start += 'T09:00:00.000Z';
    this.statisticDates.end += 'T09:00:00.000Z';
    this.filters.date = 'custom';
    this.params.page = 1;
    this.loadPieData();
  }
}
