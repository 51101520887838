
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import VueCharts from 'vue-chartjs-typescript';

@Component({})
export default class PieChart extends Mixins(VueCharts.Pie) {
  @Prop() pieData!: [{value: number; label: string; color: string}];

  @Watch('pieData') onPieChange(data, old) {
    if (JSON.stringify(data) === JSON.stringify(old)) {
      return;
    }
    this.dataReset();
    if (!data) {
      this.setDefaultData();
    } else {
      data.forEach((el: {value: number; label: string; color: string}) => {
        if (el.value) {
          this.data.datasets[0].data.push(el.value);
          this.data.labels.push(el.label);
          this.data.datasets[0].backgroundColor.push(el.color);
        }
      });
    }
    this.renderChart(this.data, this.options);
  }

  data = {
    datasets: [
      {
        data: [] as number[],
        backgroundColor: [] as string[],
        borderWidth: 0,
      },
    ],
    labels: [] as string[],
  };

  options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      labels: {
        fontSize: 20,
        fontColor: '#000',
        fontFamily: 'TestSöhne-Leicht',
      },
    },
  };

  dataReset() {
    this.data.datasets[0].data = [];
    this.data.datasets[0].backgroundColor = [];
    this.data.labels = [];
  }

  setDefaultData() {
    this.data.datasets[0].data = [1];
    this.data.datasets[0].backgroundColor = ['#aab9c1'];
    this.data.labels = [];
  }
}
