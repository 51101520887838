




























































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import moment, {DurationInputArg2} from 'moment';
import DatePicker from '@/components/DatePicker.vue';
import Modal from '../Modal.vue';
import MomentDateMixin from '@/mixins/MomentDateMixin';

@Component({
  components: {Modal, DatePicker},
})
export default class DatePickerModal extends Mixins(MomentDateMixin) {
  showModal = false;
  rangeControl = 'month';
  currentMonth = moment().format('YYYY-MM-DD');
  prevMonth = moment(this.currentMonth)
    .subtract(1, 'month')
    .format('YYYY-MM-DD');
  selectedDate: {start: string; end: string} = {
    start: moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };
  inputControls = {
    start: {day: '', month: '', year: ''},
    end: {day: '', month: '', year: ''},
  };
  minDate = moment()
    .subtract(5, 'year')
    .startOf('year')
    .format('YYYY-MM-DD');
  maxDate = moment().format('YYYY-MM-DD');
  @Prop() readonly value!: {start: string; end: string};

  @Watch('rangeControl') onRangeControlChanged() {
    if (this.rangeControl !== 'custom') {
      this.selectedDate = {
        start: moment()
          .subtract(1, this.rangeControl as DurationInputArg2)
          .format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      };
      this.currentMonth = this.selectedDate.end;
      this.prevMonth = this.selectedDate.start;
      this.syncControls();
    }
  }

  @Watch('value') onPropDatesChange() {
    this.selectedDate = this.value;
  }

  get years(): Array<string> {
    return new Array(6).fill(moment().year()).map((x, i) => x - i + '');
  }

  months(type: string): Array<string> {
    let months = 12;
    if (
      this.inputControls[type].year &&
      this.inputControls[type].year ===
        moment()
          .year()
          .toString()
    ) {
      months = moment().month() + 1;
    }
    return new Array(months).fill(1).map((x, i) => (i < 9 ? '0' : '') + (x + i));
  }

  days(type: string): Array<string> {
    let days = 31;
    if (this.inputControls[type].month) {
      const date = moment(
        (this.inputControls[type].year ? this.inputControls[type].year : moment().year()) +
          '-' +
          this.inputControls[type].month +
          '-' +
          '01',
      );
      if (date.isSame(moment().startOf('month'))) {
        days = parseInt(moment().format('D'), 10);
      } else {
        days = date.daysInMonth();
      }
    }
    return new Array(days).fill(1).map((x, i) => (i < 9 ? '0' : '') + (x + i));
  }

  mounted() {
    this.selectedDate = this.value;
    this.syncControls();
  }

  dateChanged(date: {start: string; end: string}) {
    this.selectedDate = date;
    this.syncControls();
    this.rangeControl = 'custom';
  }

  controlUpdated() {
    const start = moment(
      this.inputControls.start.year + '-' + this.inputControls.start.month + '-' + this.inputControls.start.day,
    );
    if (this.inputControls.end.month && this.inputControls.end.day && this.inputControls.end.year) {
      const end = moment(
        this.inputControls.end.year + '-' + this.inputControls.end.month + '-' + this.inputControls.end.day,
      );
      if (start.isSameOrAfter(end)) {
        this.selectedDate = {start: end.format('YYYY-MM-DD'), end: ''};
        this.syncControls();
      } else {
        this.selectedDate = {start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD')};
        this.currentMonth = this.selectedDate.end;
      }
    }
    this.prevMonth = this.selectedDate.start;
    this.rangeControl = 'custom';
  }

  syncControls() {
    const start = this.selectedDate.start.split('-');
    this.inputControls.start = {month: start[1], day: start[2], year: start[0]};
    if (this.selectedDate.end) {
      const end = this.selectedDate.end.split('-');
      this.inputControls.end = {month: end[1], day: end[2], year: end[0]};
    } else {
      this.inputControls.end = {month: '', day: '', year: ''};
    }
  }

  close() {
    this.showModal = false;
  }

  apply() {
    if (this.selectedDate.start && this.selectedDate.end) {
      this.showModal = false;
      this.$emit('dateChanged', this.selectedDate);
    }
  }
}
